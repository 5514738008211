body:not(.home){
  h2,
  .h2 {
    color: $secondary;
  }
  h3,
  .h3{
    color: $primary;
  }

  .dark {
    h2,
    .h2 {
      color: $dark-txt-color;
    }
    h3,
    .h3{
      color: $dark-txt-color;
    }
  }
}

.fw-medium {
  font-weight: 500;
}

.surtitle {
  &.fw-bold {
    font-weight: 700 !important;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
{
  letter-spacing: -1.15px;
}

