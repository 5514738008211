#nav-main {
  .menu-item {
    align-items: center;
    margin: 0;
  }

  @include media-breakpoint-up($desktop-breakpoint) {
    .submenu-toggle{
      padding: 0;
      font-size: .8rem;
    }
  }
  @include media-breakpoint-only($desktop-breakpoint) {

    .menu-item {
      .nav-link {
        font-size: .8rem;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0 .5rem;
  }
}
