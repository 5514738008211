.hero-animation-media-zoom {
  animation-name: heroMediaZoom;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
}

.alpha-loaded .featured-image > .content-above {
  --featured-content-above-offset: calc(var(--header-height-default)/ 2);
  --content-above-translate-y: calc(-50% + var(--featured-content-above-offset));
  will-change: transform;
}

@keyframes heroMediaZoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
