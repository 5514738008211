.tease-grid {
  .link-arrow {
    color: $secondary;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &.tease-accommodations {
    .card-title {
      // stylelint-disable
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // stylelint-enable
      overflow: hidden;
    }

    .promo-badge {
      top: -1.1rem;
      transform: rotate(-45deg);
      padding: 2rem 1rem 0;
      left: -2.6rem;
      background: $primary;
    }

    .badge {
      background-color: transparent;

      img {
        max-width: 80px;
      }
    }
  }
}
