.site-footer {
  .logo {
    max-width: 200px;
    &.svg-component-block {
      [fill="#484131"] {
        fill: $dark-txt-color;
      }
    }
  }

  a {
    &:not(.btn) {
      &:hover,
      &:focus {
        color: $color-light-primary;
      }

      i {
        color: currentcolor;
        transition: color .3s ease;
      }
    }
  }
}
