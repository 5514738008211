.site-header {
  .btn {
    &.btn-booking {
      @include font-awesome-before($fa-var-calendar-alt, $fa-style: "light", $margin: 0);

      @include media-breakpoint-down(xl) {
        .btn-inner-text {
          display: none;
        }
      }
    }
  }
}
